import axios from "axios";

const token = document
  .getElementsByName("csrf-token")[0]
  .getAttribute("content");

axios.defaults.headers["X-CSRF-TOKEN"] = token;
axios.defaults.headers["Content-Type"] = "application/json";

export const get = (url, config) => axios.get(url, config)
export const post = (url, data, config) => axios.post(url, data, config)
export const patch = (url, data, config) => axios.patch(url, data, config)
export const destroy = (url, config) => axios.delete(url, config)
