import Vue from 'vue/dist/vue.esm.js';
import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
import { fetchCoordinates } from 'packs/apis/public/coordinates.js'
import qs from 'qs'
import { fetchTopics } from 'packs/apis/public/topics.js'
import { topicShowPath } from 'packs/plugins/urls.js'
import TurbolinksAdapter from 'vue-turbolinks';
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  observer: true
})

Vue.use(TurbolinksAdapter);

document.addEventListener('DOMContentLoaded', () => {
  let vm = new Vue({
    el: '#show',
    components: {
      Hooper,
      Slide,
      HooperNavigation,
      HooperPagination,
    },
    data: {
      showCoordinateDetailModal: false,
      selectedShowCoordinate: {
        main_coordinate_image: {
          image_url: ""
        }
      },
      coordinates: [],
      topics: [
        {
          image: {
            url: ""
          }
        }
      ],
      coordinateHooperSettings: {
        infiniteScroll: true,
        wheelControl:false,
        autoPlay:true,
        playSpeed:2000,
        transition:1000,
        breakpoints: {
          640: {
            itemsToShow: 1.5
          },
          768: {
            itemsToShow: 2
          },
          1024: {
            itemsToShow: 3
          },
          1280: {
            itemsToShow: 4
          }
        }
      },
      topicHooperSettings: {
        infiniteScroll: true,
        wheelControl:false,
        breakpoints: {
          640: {
            itemsToShow: 1.5
          },
          768: {
            itemsToShow: 3.5
          },
        }
      }
    },
    async created() {
      this.fetchCoordinates()
    },
    mounted() {
      setInterval(() => {
        const imagesPc = document.getElementsByClassName('slideshow-pc');
        this.indexPc = this.indexPc < imagesPc.length - 1 ? this.indexPc + 1 : 0;
        this.slideshowPc(imagesPc);

        const imagesSp = document.getElementsByClassName('slideshow-sp');
        this.indexSp = this.indexSp < imagesSp.length - 1 ? this.indexSp + 1 : 0;
        this.slideshowSp(imagesSp);
      }, 3000);
    },
    computed: {
      fetchCoordinateParams() {
        const params = {
                  coordinate: { }
                }
        const paramsSerializer = (params) => qs.stringify(params, {arrayFormat: 'brackets'});

        return { params, paramsSerializer }
      },
    },
    methods: {
      slideshowPc(imagesPc) {
        this.$nextTick(() => {
          const currentPc = imagesPc[this.indexPc];
          const prevPc = imagesPc[this.indexPc - 1] ? imagesPc[this.indexPc - 1] : imagesPc[imagesPc.length - 1];
          if(currentPc) {
            currentPc.classList.add('fadein');
            currentPc.classList.remove('fadeout');
            prevPc.classList.remove('fadein');
            prevPc.classList.add('fadeout');
          }
        })
      },
      slideshowSp(imagesSp) {
        this.$nextTick(() => {
          const currentSp = imagesSp[this.indexSp];
          const prevSp = imagesSp[this.indexSp - 1] ? imagesSp[this.indexSp - 1] : imagesSp[imagesSp.length - 1];
          if(currentSp) {
            currentSp.classList.add('fadein');
            currentSp.classList.remove('fadeout');
            prevSp.classList.remove('fadein');
            prevSp.classList.add('fadeout');
          }
        })
      },
      topicShowPath(topicId) {
        return topicShowPath(topicId)
      },
      selectShowCoordinate(coordinate) {
        this.selectedShowCoordinate = coordinate
        this.showCoordinateDetailModal = true
        this.$refs['pagination-hooper'].slideTo(0)
      },
      toggleshowCoordinateDetailModal() {
        this.showCoordinateDetailModal = !this.showCoordinateDetailModal
      },
      fetchCoordinates() {
        fetchCoordinates(this.fetchCoordinateParams)
              .then((res) => {
                this.coordinates = res.data
                //console.log(this.coordinates)
                this.selectedShowCoordinate = res.data[0]
        })
      },
    }
  });
});
